import { useState } from "react";
import {
  List,
  TextField,
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput,
  Create,
  DatagridConfigurable,
  BulkDeleteButton,
  Show,
  SimpleShowLayout,
  useNotify,
  useRefresh,
  NumberField,
  DateField,
  BooleanField,
  ArrayField,
  SingleFieldList,
  ChipField,
  ImageField,
  FunctionField,
  Datagrid,
  ReferenceField,
  ReferenceInput,
  required,
  useInput,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import DialogWrapper from "../commonRA/DialogWrapper";
import EditToolbar from "../commonRA/EditToolbar";
import StandartListAction from "../commonRA/StandartListAction";
import ActionsField from "../commonRA/ActionsField";
import { Box } from "@mui/material";
import TextFieldInline from "../commonRA/TextFieldInline";
import DateFieldInline from "../commonRA/DateFieldInline";
import BooleanFieldInline from "../commonRA/BooleanFieldInline";
import ProductListPath from "../../../components/ProductsList/ProductListPath";
import SelectCategory from "../../../components/Dictionaries/SelectCaregory/SelectCategory";

const filters = [
  <TextInput source="q" label="Пошук" alwaysOn key="search-filter" />,
  <ReferenceInput
    source="categoryID"
    label="Категорія"
    reference="catalog/get_categories"
    alwaysOn
    key="category-filter"
  />,
];

export const ProductsList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
    >
      <TextField source="id" label="ID" />
      <ImageField source="full_image" label="Зображення" />
      <TextField source="name" label="Назва" />
      <TextField source="articul" label="Артикул" />
      <TextField source="product_code" label="Код Brain" />
      <ReferenceField
        source="categoryID"
        reference="catalog/get_categories"
        label="Категорія"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="brand"
        reference="catalog/get_brands"
        label="Виробник"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="warranty" label="Гарантія, міс" />
      <NumberField source="count_of_images" label="К-ть зображень" />
      <TextField source="price_im" label="Ціна магазина" />
      <TextField source="recommendable_price" label="Рекомендована" />
      <TextField source="bonus" label="Бонус Brain" />
      <TextField source="brief_description" label="Опис" />
      <TextField source="country" label="Країна" />
      <TextField source="koduktved" label="УКТЗЕД" />
      <TextField source="EAN" label="Штрихкод" />
      <DateField source="date_modified" label="Дата змін" />
      <BooleanField source="xit" label="ХІТ" />
      <BooleanField source="onoff" label="Доступність" />
      <BooleanField source="all_filds_done" label="Заповненість" />
      <ActionsField />
    </DatagridConfigurable>
  </List>
);

export const ProductsShow = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  return (
    <DialogWrapper open={open} onClose={handleClose}>
      <Show {...props}>
        <SimpleShowLayout>
          <Box>
            <Box mb={1}>
              <FunctionField
                render={(record) => (
                  <ProductListPath
                    categoryID={record.categoryID}
                    name={record.name}
                  />
                )}
              />
            </Box>
            <Box display="flex">
              <Box display="flex">
                <ImageField source="full_image" title="Зображення" />
              </Box>
              <Box>
                <Box display="flex">
                  <TextFieldInline source="id" label="ID:" />
                  <TextFieldInline source="articul" label="Артикул:" />
                  <TextFieldInline source="product_code" label="Код брейн:" />
                  <DateFieldInline
                    source="date_modified"
                    label="Оновлювалось:"
                  />
                  <BooleanFieldInline source="onoff" label="Наявність:" />
                </Box>
                <Box display="flex">
                  <TextFieldInline source="price_im" label="Ціна:" />
                  <FunctionField
                    label="Рекомендована ціна:"
                    render={(record) =>
                      Number(record.recommendable_price) !== 0 ? (
                        <TextFieldInline
                          source="recommendable_price"
                          label="Рекомендована ціна:"
                        />
                      ) : null
                    }
                  />
                  <FunctionField
                    label="Бонус брейн:"
                    render={(record) =>
                      Number(record.bonus) !== 0 ? (
                        <TextFieldInline source="bonus" label="Бонус брейн:" />
                      ) : null
                    }
                  />
                </Box>
                <Box display="flex">
                  <TextFieldInline source="warranty" label="Гарантія:" />
                  <TextFieldInline source="country" label="Країна:" />
                  <TextFieldInline source="koduktved" label="Код УКТВЕД:" />
                  <TextFieldInline source="EAN" label="EAN:" />
                </Box>
                <Box display="flex">
                  <TextFieldInline
                    source="brief_description"
                    label="Короткий опис:"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <ArrayField source="prices" label="Ціни вхідні">
            <Datagrid>
              <TextField source="vendor" label="Постачальник" />
              <TextField source="vendor_code" label="Код постачальника" />
              <ReferenceField
                source="brand"
                reference="catalog/get_brands_vendors"
                label="Виробник по постачальнику"
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                source="brand_catalog"
                reference="catalog/get_brands"
                label="Виробник по каталогу"
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="price" label="Ціна" />
              <TextField source="price_bez_pdv" label="Ціна без ПДВ" />
              <TextField source="quantity" label="Кількість" />
              <DateField source="delivery_date" label="На коли" />
              <BooleanField source="available" label="Доступність" />
              <BooleanField source="blocked" label="Забалоковано" />
              <ActionsField />
            </Datagrid>
          </ArrayField>
          <ArrayField source="sale_prices" label="Ціни вихідні">
            <Datagrid>
              <TextField source="code" label="Категорія ціни" />
              <TextField source="price" label="Ціна" />
              <DateField source="change_date" label="Дата оновлення" />
            </Datagrid>
          </ArrayField>
          <FunctionField
            render={(record) =>
              record.options && record.options.length > 0 ? (
                <ArrayField source="options">
                  <SingleFieldList>
                    <ChipField source="name" />
                  </SingleFieldList>
                </ArrayField>
              ) : null
            }
          />
          <FunctionField
            render={(record) =>
              record.filters && record.filters.length > 0 ? (
                <ArrayField source="filters">
                  <SingleFieldList>
                    <ChipField source="id" />
                  </SingleFieldList>
                </ArrayField>
              ) : null
            }
          />
          <BooleanField source="xit" />
          <BooleanField source="all_filds_done" />
        </SimpleShowLayout>
      </Show>
    </DialogWrapper>
  );
};

export const EditCreateForm = () => {
  const [categorySelectOpen, setCategorySelectOpen] = useState(false);
  const [title, setTitle] = useState("");
  // Використовуємо useInput для інтеграції з React Admin
  const {
    field: { value: selectedCategory, onChange: setSelectedCategory },
  } = useInput({ source: "categoryID" });

  const modalOpen = (e) => {
    setCategorySelectOpen(true);
    setTitle("Обераємо категорію:");
  };

  const modalClose = () => {
    setCategorySelectOpen(false);
    setTitle("");
  };

  return (
    <>
      <TextInput source="name" label="Назва товару:" validate={required()} />
      <TextInput
        source="articul"
        label="Артикул товару:"
        validate={required()}
      />
      <TextInput source="product_code" label="Код за BRAIN:" />
      <TextInput
        source="categoryID"
        label="Категорія товару:"
        onClick={modalOpen}
        value={selectedCategory} // Відображає вибране значення
        onChange={() => {}} // Забороняємо ручне редагування
      />
      {categorySelectOpen && (
        <SelectCategory
          title={title}
          selectedID={0}
          setSelectedCategory={(category) => {
            setSelectedCategory(category.categoryID); // Передаємо тільки categoryID
            modalClose(); // Закриваємо модальне вікно
          }}
          modalClose={modalClose}
        />
      )}
      <TextInput source="brief_description" label="Опис:" />
      <TextInput source="price_im" label="Ціна продажу:" />
      <TextInput source="recommendable_price" label="Рекомендована:" />
      <TextInput source="full_image" label="Зображення:" />
      <TextInput source="koduktved" label="Код УКТВЕД:" />
      <TextInput source="EAN" label="Штрихкод:" />
      <TextInput source="warranty" label="Гарантія, міс:" />
      <TextInput source="country" label="Країна:" />
      <BooleanInput source="onoff" label="Доступність:" />
    </>
  );
};

export const ProductsEdit = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно відредаговано!");
    handleClose();
    refresh();
  };

  return (
    <DialogWrapper open={open} onClose={handleClose} title="Редагування картки">
      <Edit {...props} onSuccess={onSuccess}>
        <SimpleForm toolbar={<EditToolbar />}>
          <TextInput
            source="id"
            slotProps={{
              input: {
                disabled: true,
              },
            }}
          />
          <EditCreateForm />
        </SimpleForm>
      </Edit>
    </DialogWrapper>
  );
};

export const ProductsCreate = (props) => {
  const [open, setOpen] = useState(true); // Відкриття модального вікна
  const navigate = useNavigate();
  const notify = useNotify();

  const handleClose = () => {
    setOpen(false);
    navigate(-1); // Повернення на попередню сторінку
  };

  const onSuccess = (data) => {
    // Виконання логіки після успішного створення
    notify("Картку успішно створено!");
    handleClose();
  };

  return (
    <DialogWrapper
      open={open}
      onClose={handleClose}
      title="Створення нової картки"
    >
      <Create {...props} mutationOptions={{ onSuccess }}>
        <SimpleForm>
          <EditCreateForm />
        </SimpleForm>
      </Create>
    </DialogWrapper>
  );
};
