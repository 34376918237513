import apiaxios from "../../common/apiaxios";
import { changeBasketItems } from "../../redux/basketSlice";

export const saveBasket = (
  userStatus,
  dispatch,
  basketOrderID,
  basketDate,
  basket,
  basketCount,
  basketAmount,
  basketAmountIn,
  basketClient,
  basketWarehouse,
  basketFirm,
  basketManager,
  basketConfirm,
  basketPriceScheme,
  basketPlanDate,
  basketComent,
  basketDelivery
) => {
  if (userStatus !== "guest") {
    const newStorage = {
      basketDate: basketDate,
      basketOrderID: basketOrderID,
      items: basket,
      basketCount: basketCount,
      basketAmount: basketAmount,
      basketAmountIn: basketAmountIn,
      basketClient: basketClient,
      basketWarehouse: basketWarehouse,
      basketFirm: basketFirm,
      basketManager: basketManager,
      basketConfirm: basketConfirm,
      basketPriceScheme: basketPriceScheme,
      basketPlanDate: basketPlanDate,
      basketComent: basketComent,
      basketDelivery: basketDelivery,
    };
    let updatedBasket = [];
    apiaxios
      .post(
        "/orders/save_cart/",
        JSON.stringify(newStorage),
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((response) => {
        updatedBasket = basket.map((item) => {
          const matchingRow = response.data["rows_dict"].find(
            (row) => row.row_num === item.row_num
          );
          if (matchingRow) {
            return { ...item, row_id: matchingRow.row_id }; // Оновлюємо row_id
          }
          return item; // Якщо відповідного рядка немає, залишаємо без змін
        });
        dispatch(changeBasketItems(updatedBasket));
      })
      .catch((error) => {
        console.error("Помилка при збереженні:", error);
      });
  } else {
    const newStorage = {
      basketDate: basketDate,
      items: basket,
      basketCount: basketCount,
      basketAmount: basketAmount,
    };
    localStorage.setItem("basketStorage", JSON.stringify(newStorage));
  }
};
