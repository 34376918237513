import css from "./CatalogFiltersSidebar.module.css";
import { Card } from "@mui/material";
import Search from "../../../components/Search/Search";
import { matchPath, useLocation } from "react-router-dom";
import CategoriesFilters from "../../../components/CategoriesFilters/CategoriesFilters";
import CatalogMenuPortal from "../CatalogMenuPortal/CatalogMenuPortal";
import CategoriesFiltersSearch from "../../../components/CategoriesFilters/CategoriesFiltersSearch";
import { useEffect, useState } from "react";

const getMenuComponent = (pathname, active) => {
  const categoryMatch = matchPath(
    "/admin_portal/catalog/category/:categoryID",
    pathname
  );
  const groupMatch = matchPath(
    "/admin_portal/catalog/group_menu/:categoryID",
    pathname
  );
  if (categoryMatch && categoryMatch.params?.categoryID) {
    return () => (
      <>
        {active === "catalog" && <CatalogMenuPortal />}
        {active === "filters" && (
          <CategoriesFilters categoryID={categoryMatch.params.categoryID} />
        )}
      </>
    );
  }
  if (groupMatch && groupMatch.params?.categoryID) {
    return () => <CatalogMenuPortal />;
  }
  if (
    pathname.startsWith("/admin_portal/catalog/main") ||
    pathname.startsWith("/admin_portal/search")
  ) {
    return () => (
      <>
        {active === "catalog" && <CatalogMenuPortal />}
        {active === "filters" && <CategoriesFiltersSearch />}
      </>
    );
  }
  if (
    pathname.startsWith("/admin_portal/catalog/main") ||
    pathname.startsWith("/admin_portal/catalog/price_inventory") ||
    pathname.startsWith("/admin_portal/catalog/product_details") ||
    pathname.startsWith("/admin_portal/catalog/favorite") ||
    pathname.startsWith("/admin_portal/catalog/compare")
  ) {
    return () => <CatalogMenuPortal />;
  }
};

const CatalogFiltersSidebar = () => {
  const [active, setActive] = useState("catalog");
  const location = useLocation();
  const MenuComponent = getMenuComponent(location.pathname, active);
  useEffect(() => {
    if (
      location.pathname.startsWith("/admin_portal/catalog/main") ||
      location.pathname.startsWith("/admin_portal/catalog/price_inventory") ||
      location.pathname.startsWith("/admin_portal/catalog/product_details") ||
      location.pathname.startsWith("/admin_portal/catalog/group_menu") ||
      location.pathname.startsWith("/admin_portal/catalog/favorite") ||
      location.pathname.startsWith("/admin_portal/catalog/compare")
    ) {
      setActive("catalog");
    } else {
      setActive("filters");
    }
  }, [location.pathname]);
  return (
    <>
      <div style={{ margin: "10px 0 0 15px", width: "250px" }}>
        <Search />
      </div>
      <div className={css["button-container"]}>
        <div
          className={
            active === "catalog" ? css["button-active"] : css["button"]
          }
          onClick={() => setActive("catalog")}
        >
          Каталог
        </div>
        <div
          className={
            active === "filters" ? css["button-active"] : css["button"]
          }
          onClick={() => setActive("filters")}
        >
          Фільтри
        </div>
      </div>
      <Card sx={{ m: 1, p: 0 }}>
        <MenuComponent />
      </Card>
    </>
  );
};

export default CatalogFiltersSidebar;
