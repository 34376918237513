import Sprite from "../common/Sprite";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  CanAccess,
  LoadingIndicator,
  Logout,
  UserMenu,
  useSidebarState,
  useUserMenu,
} from "react-admin";
import { Link, matchPath, useLocation } from "react-router-dom";
import FavoriteIcon from "../components/Header/FavoriteIcon";
import { ShoppingCartButton } from "./components/ShoppingCart/ShoppingCartButton";
import CompareIcon from "../components/Header/CompareIcon";

const Header = () => {
  const title = "Суми ІТ-сервіс";
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useSidebarState();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  let currentPath = matchPath("/admin_portal/", location.pathname)
    ? "/admin_portal/"
    : matchPath("/admin_portal/catalog/products_list/*", location.pathname)
    ? "/admin_portal/catalog/products_list"
    : matchPath("/admin_portal/dictionaries/*", location.pathname)
    ? "/admin_portal/dictionaries"
    : matchPath("/admin_portal/registers/*", location.pathname)
    ? "/admin_portal/registers"
    : false;

  return (
    <Box component="nav" sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          width: "100vw", // Ширина на весь екран
          height: "50px",
          left: 0, // Забезпечує правильне позиціонування
          marginBottom: 1,
          paddingRight: 2,
        }}
      >
        <div className="hide">
          <Sprite />
        </div>
        <Toolbar variant="dense">
          <Box flex={1} display="flex" justifyContent="space-between">
            <Box
              display="flex"
              alignItems="center"
              component={Link}
              to="/admin_portal/"
              sx={{
                color: "inherit",
                textDecoration: "inherit",
              }}
              gap={1.5}
            >
              <MenuIcon onClick={toggleSidebar} />
              {/* <Box component="img" sx={{ height: 24 }} src={logo} alt={title} /> */}
              <Typography component="span" variant="h5">
                {title}
              </Typography>
            </Box>
            <Box>
              <Tabs
                value={currentPath}
                aria-label="Navigation Tabs"
                indicatorColor="secondary"
                textColor="inherit"
              >
                <Tab
                  label={"Головна"}
                  component={Link}
                  to="/admin_portal/"
                  value="/admin_portal/"
                />
                <Tab
                  label={"Каталог"}
                  component={Link}
                  to="/admin_portal/catalog/main"
                  value="/admin_portal/catalog/main"
                />
                <Tab
                  label={"Прайс"}
                  component={Link}
                  to="/admin_portal/catalog/price_inventory"
                  value="/admin_portal/catalog/price_inventory"
                />
                <Tab
                  label={"Продажі"}
                  component={Link}
                  to="/admin_portal/orders/list"
                  value="/admin_portal/orders/list"
                />
                <Tab
                  label={"Ремонти"}
                  component={Link}
                  to="/admin_portal/dictionaries"
                  value="/admin_portal/dictionaries"
                />
                <Tab
                  label={"Картриджі"}
                  component={Link}
                  to="/admin_portal/registers"
                  value="/admin_portal/registers"
                />
                <Tab
                  label={"Інтернет-магазин"}
                  component="a" // Використовуємо звичайний <a> тег
                  href="/" // URL для посилання
                  target="_blank" // Відкриває в новій вкладці
                  rel="noopener noreferrer" // Для безпеки
                  value="/"
                />
              </Tabs>
            </Box>
            <Box display="flex" alignItems="center">
              <FavoriteIcon />
              <CompareIcon />
              <ShoppingCartButton />
              <LoadingIndicator />
              <UserMenu>
                <ConfigurationMenu />
                <CanAccess resource="sales" action="list">
                  <UsersMenu />
                </CanAccess>
                <Logout />
              </UserMenu>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

const UsersMenu = () => {
  const { onClose } = useUserMenu() ?? {};
  return (
    <MenuItem component={Link} to="/sales" onClick={onClose}>
      <ListItemIcon>
        <PeopleIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Users</ListItemText>
    </MenuItem>
  );
};

const ConfigurationMenu = () => {
  const { onClose } = useUserMenu() ?? {};
  return (
    <MenuItem component={Link} to="/settings" onClick={onClose}>
      <ListItemIcon>
        <SettingsIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>My info</ListItemText>
    </MenuItem>
  );
};
export default Header;
