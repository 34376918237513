import {
  List,
  TextField,
  ReferenceField,
  TextInput,
  ReferenceInput,
  DatagridConfigurable,
  DateField,
  NumberField,
  AutocompleteInput,
} from "react-admin";
import StandartListAction from "../commonRA/StandartListAction";

const filters = [
  <ReferenceInput
    source="source"
    reference="sources/get_sources"
    alwaysOn
    key="sources-filter"
  >
    <AutocompleteInput
      label="Постачальник"
      filterToQuery={(searchText) => ({ code: searchText })} // Фільтр по полю "code"
    />
  </ReferenceInput>,
  <ReferenceInput
    source="manager"
    reference="staff/get_staff"
    alwaysOn
    key="staff-filter"
  >
    <AutocompleteInput label="Менеджер" />
  </ReferenceInput>,
  <ReferenceInput
    source="client"
    reference="partners/get_partners"
    alwaysOn
    key="client-filter"
  >
    <AutocompleteInput label="Клієнт" />
  </ReferenceInput>,
  <ReferenceInput
    source="firm"
    reference="firms/get_firms"
    alwaysOn
    key="firms-filter"
  >
    <AutocompleteInput label="Фірма" />
  </ReferenceInput>,
  <TextInput
    source="q"
    label="Пошук по назві товару"
    alwaysOn
    key="search-filter"
  />,
];

export const OrderedRowsList = () => (
  <List actions={<StandartListAction />} filters={filters}>
    <DatagridConfigurable
      bulkActionButtons={false}
      sx={{
        "& .MuiTypography-root, & .MuiTableCell-root": {
          fontSize: "0.75rem !important",
        },
      }}
    >
      <TextField source="id" label="№" />
      <ReferenceField
        source="item"
        reference="catalog/get_products"
        label="Товар"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="quantity" label="Кількість" />
      <TextField source="price_in" label="Ціна" />
      <TextField source="source" label="Постачальник" />
      <DateField source="plan_date" label="На дату" />
      <ReferenceField
        source="client"
        reference="partners/get_partners"
        label="Клієнт"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="firm"
        reference="firms/get_firms"
        label="Фірма"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="manager"
        reference="staff/get_staff"
        label="Менеджер"
        emptyText="-"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="order"
        reference="orders/list"
        label="Замовлення"
        emptyText="-"
      >
        Замовлення № <TextField source="id" />
      </ReferenceField>
    </DatagridConfigurable>
  </List>
);
