import { Menu } from "react-admin";
import ListIcon from "@mui/icons-material/List";
import LabelIcon from "@mui/icons-material/Label";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import CatalogMenuPortal from "./components/CatalogMenuPortal/CatalogMenuPortal";

const PortalMenu = () => {
  const [openDict, setOpenDict] = useState(false);
  const [openReg, setOpenReg] = useState(false);
  const [openCatalog, setOpenCatalog] = useState(false);
  return (
    <Menu width={"100%"}>
      <MenuItem onClick={() => setOpenCatalog(!openCatalog)}>
        <ListItemIcon>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary="Каталог товарів" />
        <ListItemIcon>
          {!openCatalog && <AddTwoToneIcon sx={{ fontSize: 15 }} />}
          {openCatalog && <RemoveTwoToneIcon sx={{ fontSize: 15 }} />}
        </ListItemIcon>
      </MenuItem>
      <Collapse in={openCatalog} timeout="auto" unmountOnExit sx={{ pl: 1 }}>
        <CatalogMenuPortal />
      </Collapse>
      <MenuItem onClick={() => setOpenDict(!openDict)}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Довідники" />
        <ListItemIcon>
          {!openDict && <AddTwoToneIcon sx={{ fontSize: 15 }} />}
          {openDict && <RemoveTwoToneIcon sx={{ fontSize: 15 }} />}
        </ListItemIcon>
      </MenuItem>
      <Collapse in={openDict} timeout="auto" unmountOnExit sx={{ pl: 1 }}>
        <Menu.ResourceItem name="firms/get_firms" />
        <Menu.ResourceItem name="firms/get_accounts" />
        <Menu.ResourceItem name="staff/get_staff" />
        <Menu.ResourceItem name="users/get_users" />
        <Menu.ResourceItem name="partners/get_partners" />
        <Menu.ResourceItem name="sources/get_sources" />
        <Menu.ResourceItem name="catalog/get_categories" />
        <Menu.ResourceItem name="catalog/get_products" />
        <Menu.ResourceItem name="catalog/get_prices_in" />
        <Menu.ResourceItem name="catalog/get_prices_out" />
        <Menu.ResourceItem name="orders/delivery" />
        <Menu.ResourceItem name="catalog/get_brands" />
        <Menu.ResourceItem name="catalog/get_brands_vendors" />
      </Collapse>
      <MenuItem onClick={() => setOpenReg(!openReg)}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Реєстри" />
        <ListItemIcon>
          {!openReg && <AddTwoToneIcon sx={{ fontSize: 15 }} />}
          {openReg && <RemoveTwoToneIcon sx={{ fontSize: 15 }} />}
        </ListItemIcon>
      </MenuItem>
      <Collapse in={openReg} timeout="auto" unmountOnExit sx={{ pl: 1 }}>
        <Menu.ResourceItem name="orders/list" />
        <Menu.ResourceItem name="orders/ordering_rows" />
      </Collapse>
    </Menu>
  );
};

export default PortalMenu;

// import LabelIcon from "@mui/icons-material/Label";

/* <Menu.Item
        to="/firms/get_firms"
        primaryText="Зразок пункту з поиланням"
        leftIcon={<LabelIcon />}
      /> */

/* <MenuItem   - зразок підпунклів в колапс
          component="a"
          href="/#/firms/get_firms"
          sx={{ pl: 4 }} // Додає відступ 4 одиниці (padding-left)
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Список фірм" />
        </MenuItem>
        <MenuItem
          component="a"
          href="/#/firms/get_accounts"
          sx={{ pl: 4 }} // Додає відступ 4 одиниці (padding-left)
        >
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <ListItemText primary="Рахунки фірм" />
        </MenuItem> */
