import { Suspense } from "react";
import { CssBaseline, Box } from "@mui/material";
import {
  Error,
  Loading,
  CheckForApplicationUpdate,
  Sidebar,
} from "react-admin";
import { ErrorBoundary } from "react-error-boundary";
import Header from "./PortalHeader";
import PortalMenu from "./PortalMenu";
import { useLocation } from "react-router-dom";
import CatalogFiltersSidebar from "./components/CatalogFiltersSidebar/CatalogFiltersSidebar";

const getMenuComponent = (pathname) => {
  if (
    pathname.startsWith("/admin_portal/catalog/main") ||
    pathname.startsWith("/admin_portal/catalog/price_inventory") ||
    pathname.startsWith("/admin_portal/catalog/category") ||
    pathname.startsWith("/admin_portal/catalog/group_menu") ||
    pathname.startsWith("/admin_portal/catalog/product_details") ||
    pathname.startsWith("/admin_portal/catalog/favorite") ||
    pathname.startsWith("/admin_portal/catalog/compare") ||
    pathname.startsWith("/admin_portal/search")
  ) {
    return CatalogFiltersSidebar;
  }
  return PortalMenu;
};

export const PortalLayout = ({ children }) => {
  const location = useLocation();
  const MenuComponent = getMenuComponent(location.pathname);

  return (
    <>
      <CssBaseline />
      <Header />
      <Box sx={{ mt: "53px" }}>
        <main id="main-content">
          <ErrorBoundary FallbackComponent={Error}>
            <Suspense fallback={<Loading />}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexGrow: 1,
                }}
              >
                <Sidebar>
                  <MenuComponent />
                </Sidebar>
                <Box
                  display="flex"
                  flexDirection="column"
                  flexGrow={2}
                  p={1}
                  ml={3}
                >
                  {children}
                </Box>
              </Box>
            </Suspense>
          </ErrorBoundary>
        </main>
      </Box>
      <CheckForApplicationUpdate interval={30 * 1000} />
    </>
  );
};
