import { useContext, useEffect, useState } from "react";
import Basket from "./Basket";
import css from "./Basket.module.css";
import modalStatusContext from "../../context/modalStatusContext";
import Modal from "../../common/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketCount,
  changeBasketItems,
  changeBasketPlanDate,
} from "../../redux/basketSlice";
import {
  selectBasketAmount,
  selectBasketAmountIn,
  selectBasketCount,
  selectBasketClient,
  selectBasketComent,
  selectBasketConfirm,
  selectBasketDelivery,
  selectBasketFirm,
  selectBasketItems,
  selectBasketManager,
  selectBasketOrderID,
  selectBasketPriceScheme,
  selectBasketWarehouse,
} from "../../redux/basketSelectors";
import CountCircle from "../CountCircle/CountCircle";
import { saveBasket } from "./saveBasket";

const BuyButtonModal = ({
  id,
  name,
  full_image,
  price_im,
  price_max,
  quantity,
  prices,
  sale_prices,
  // vendor,
  // price_in,
  // plan_date = "",
}) => {
  const { modalIsOpen, openModal, closeModal, contentModal } =
    useContext(modalStatusContext);
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user.status);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basketDate = useSelector((state) => state.basket.updateDate);
  const basket = useSelector(selectBasketItems);
  const basketCount = useSelector(selectBasketCount);
  const basketAmount = useSelector(selectBasketAmount);
  const basketAmountIn = useSelector(selectBasketAmountIn);
  const basketClient = useSelector(selectBasketClient);
  const basketWarehouse = useSelector(selectBasketWarehouse);
  const basketFirm = useSelector(selectBasketFirm);
  const basketManager = useSelector(selectBasketManager);
  const basketConfirm = useSelector(selectBasketConfirm);
  const basketPriceScheme = useSelector(selectBasketPriceScheme);
  const basketPlanDate = useSelector((state) => state.basket.planDate);
  const basketComent = useSelector(selectBasketComent);
  const basketDelivery = useSelector(selectBasketDelivery);
  const [quantBasket, setQuantBasket] = useState(0);

  // if (quantity && quantBasket > quantity) {
  //   alert("BuyButtonModal");
  // }

  useEffect(() => {
    // const item = basket.find((item) => item.id === id);
    // setQuantBasket(item ? item.quantity : 0);
    const totalQuantity = basket.reduce((sum, item) => {
      return item.id === id ? sum + item.quantity : sum;
    }, 0);
    setQuantBasket(totalQuantity);
  }, [basket, id]);

  const addToBasket = (
    id,
    name,
    full_image,
    price_im,
    prices
    // vendor,
    // price_in,
    // plan_date
  ) => {
    const price_in = prices ? prices[0]["price"] : null;
    const plan_date = prices ? prices[0]["delivery_date"] : null;
    const vendor = prices ? prices[0]["vendor"] : null;
    let changedBasket = basket.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: item.quantity + 1,
        };
      }
      return item;
    });
    const itemExists = changedBasket.some((item) => item.id === id);
    let price = price_im;
    if (basketWarehouse["id"] !== 0) {
      price = price_in;
    } else {
      if (basketPriceScheme === "RTL") {
        price = price_im;
      } else {
        for (let item in sale_prices) {
          if (sale_prices[item]["code"] === basketPriceScheme) {
            price = sale_prices[item]["price"];
          }
        }
      }
    }
    if (!itemExists) {
      const newItem = {
        row_id: null,
        row_num: basket.length + 1,
        id: id,
        name: name,
        full_image: full_image,
        price_im: price,
        quantity: 1,
        available: true,
        vendor:
          userStatus === "staff" || userStatus === "super" ? vendor : undefined,
        price_in:
          userStatus === "staff" || userStatus === "super"
            ? price_in
            : undefined,
        plan_date: plan_date,
      };
      changedBasket = [...changedBasket, newItem];
    }

    // Обчислюємо нові значення
    const newBasketCount = basketCount + 1;
    const newBasketAmount = basketAmount + price;
    const newBasketAmountIn = basketAmountIn + price_in;
    let changedPlanDate = basketPlanDate;
    if (basketPlanDate < plan_date) {
      changedPlanDate = plan_date;
      dispatch(changeBasketPlanDate(plan_date));
    }

    // Оновлюємо Redux state
    dispatch(changeBasketItems(changedBasket));
    dispatch(changeBasketCount(newBasketCount));
    dispatch(changeBasketAmount(newBasketAmount));
    dispatch(changeBasketAmountIn(newBasketAmountIn));

    // Передаємо обчислені значення у saveAllBasket
    saveBasket(
      userStatus,
      dispatch,
      basketOrderID,
      basketDate,
      changedBasket,
      newBasketCount,
      newBasketAmount,
      newBasketAmountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      changedPlanDate,
      basketComent,
      basketDelivery
    );
  };

  return (
    <>
      <div
        className={css["btn-buy-container"]}
        onClick={(e) => {
          if (userStatus !== "super" && userStatus !== "staff") {
            openModal(e);
            e.stopPropagation();
          }
          addToBasket(
            id,
            name,
            full_image,
            price_im,
            prices
            // vendor,
            // price_in,
            // plan_date
          );
        }}
        id={id}
      >
        <div className={css["btn-buy"]}>
          <div className={css["btn-buy-svg"]}>
            <svg width="13" height="13">
              <use xlinkHref="#svg-icon__cart" />
            </svg>
            {quantBasket !== 0 && (
              <div className={css["quantity_circle"]}>
                <CountCircle count={quantBasket} />
              </div>
            )}
          </div>
        </div>
        <div>
          <div className={css["price-retail"]}>{price_im} ₴</div>
          {price_im !== price_max && (
            <div className={css["price-retail-max"]}>{price_max} ₴</div>
          )}
        </div>
      </div>
      {modalIsOpen && contentModal === String(id) && (
        <Modal>
          <div className={css["modal-basket-container"]}>
            <Basket modalClose={closeModal} />
          </div>
        </Modal>
      )}
    </>
  );
};

export default BuyButtonModal;
