import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import Basket from "./Basket";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import {
  selectBasketItems,
  selectBasketOrderID,
} from "../../redux/basketSelectors";
import apiaxios from "../../common/apiaxios";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketClient,
  changeBasketComent,
  changeBasketConfirm,
  changeBasketCount,
  changeBasketDelivery,
  changeBasketFirm,
  changeBasketItems,
  changeBasketManager,
  changeBasketOrderID,
  changeBasketPlanDate,
  changeBasketPriceScheme,
  changeBasketUpdateDate,
  changeBasketWarehouse,
} from "../../redux/basketSlice";
import { saveBasket } from "./saveBasket";
import { useLocation } from "react-router-dom";

const EditOrderByCart = ({ record, context = "" }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    if (location.pathname.startsWith("/admin_portal/orders/list")) {
      window.location.reload();
    }
  };
  const userStatus = useSelector((state) => state.user.status);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basket = useSelector(selectBasketItems);

  const handleClick = () => {
    if (basketOrderID || basket.length !== 0) {
      alert("У кошику знаходиться незбережене замовлення!");
    } else if (record.blocked_by_user) {
      alert("Замовлення редагується іншим користувачем!");
    } else {
      apiaxios
        .get("/orders/get_order_by_id?id=" + record.id)
        .then((res) => {
          dispatch(changeBasketItems([...res.data.items]));
          const basketCount = res.data.items.reduce(
            (sum, item) => sum + item.quantity,
            0
          );
          dispatch(changeBasketCount(basketCount));
          dispatch(changeBasketAmount(res.data.amount));
          const basketAmountIn = res.data.items.reduce(
            (sum, item) => sum + item.quantity * item.price_in,
            0
          );
          dispatch(changeBasketAmountIn(basketAmountIn));
          dispatch(changeBasketOrderID(record.id));
          dispatch(changeBasketUpdateDate(Date().slice(0, 15)));
          dispatch(changeBasketClient(res.data.client));
          dispatch(changeBasketWarehouse(res.data.warehouse));
          dispatch(changeBasketFirm(res.data.firm));
          dispatch(changeBasketManager(res.data.manager));
          dispatch(changeBasketConfirm(res.data.confirm));
          dispatch(changeBasketPriceScheme(res.data.price_scheme));
          dispatch(changeBasketPlanDate(res.data.plan_date));
          dispatch(changeBasketComent(res.data.coment));
          dispatch(changeBasketDelivery(res.data.delivery));
          saveBasket(
            userStatus,
            dispatch,
            record.id,
            Date().slice(0, 15),
            [...res.data.items],
            basketCount,
            res.data.amount,
            basketAmountIn,
            res.data.client,
            res.data.warehouse,
            res.data.firm,
            res.data.manager,
            res.data.confirm,
            res.data.price_scheme,
            res.data.plan_date,
            res.data.coment,
            res.data.delivery
          );
        })
        .catch((err) => {
          console.log(err);
        });
      handleOpen();
    }
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Box
        onClick={handleClick}
        style={{ display: "flex", fontSize: "0.8rem", color: "blue" }}
      >
        <IconButton color="inherit" style={{ padding: 0 }}>
          <div style={{ fill: "blue" }}>
            <svg width={"17"} height={"17"}>
              <use xlinkHref="#svg-icon__cart" />
            </svg>
          </div>
        </IconButton>
        <div style={{ marginLeft: "10px", marginTop: "5px" }}>{context}</div>
      </Box>

      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogContent style={{ padding: 0 }}>
          <Basket modalClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditOrderByCart;
