import { Menu, MenuItem, IconButton, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { DeleteWithConfirmButton } from "react-admin";
import EditOrderByCart from "../../../components/Basket/EditOrderByCart";
import apiaxios from "../../../common/apiaxios";

export const OrderActionsMenu = ({ record }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOrder = () => {
    console.log("handleOrder", record);
    apiaxios.post(
      "/orders/order_to_work/",
      record["id"],
      { headers: { "Content-Type": "application/json" } },
      { withCredentials: true }
    );
    window.location.reload();
    handleClose();
  };

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <IconButton
        aria-label="more"
        aria-controls="order-actions-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="order-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <Box
            onClick={handleOrder}
            style={{ display: "flex", fontSize: "0.8rem", color: "blue" }}
          >
            <IconButton color="inherit" style={{ padding: 0 }}>
              <div style={{ fill: "blue" }}>
                <svg width={"17"} height={"17"}>
                  <use xlinkHref="#svg-icon__truck" />
                </svg>
              </div>
            </IconButton>
            <div style={{ marginLeft: "10px", marginTop: "5px" }}>ЗАМОВИТИ</div>
          </Box>
        </MenuItem>
        <MenuItem>
          <EditOrderByCart record={record} context="РЕДАГУВАТИ" />
        </MenuItem>
        <MenuItem>
          <DeleteWithConfirmButton
            confirmContent="Ви впевені, що бажаєте цього?"
            confirmColor="warning"
            translateOptions={{ name: "замовлення" }}
            label="Видалити"
            sx={{
              padding: 0,
              paddingTop: "3px",
              paddingBottom: "3px",
              margin: 0,
              minWidth: "unset", // Прибираємо мінімальну ширину
            }}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};
