import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import css from "./Basket.module.css";
import {
  changeBasketAmount,
  changeBasketAmountIn,
  changeBasketCount,
  changeBasketItems,
  changeBasketPlanDate,
} from "../../redux/basketSlice";
import {
  selectBasketClient,
  selectBasketComent,
  selectBasketConfirm,
  selectBasketDelivery,
  selectBasketFirm,
  selectBasketItems,
  selectBasketManager,
  selectBasketOrderID,
  selectBasketPriceScheme,
  selectBasketWarehouse,
} from "../../redux/basketSelectors";
import apiaxios from "../../common/apiaxios";
import { saveBasket } from "./saveBasket";

const BasketRowVendorSelect = ({ current_vendor, id, row_num }) => {
  const dispatch = useDispatch();
  const userStatus = useSelector((state) => state.user.status);
  const basketOrderID = useSelector(selectBasketOrderID);
  const basketDate = useSelector((state) => state.basket.updateDate);
  const basket = useSelector(selectBasketItems);
  const basketClient = useSelector(selectBasketClient);
  const basketWarehouse = useSelector(selectBasketWarehouse);
  const basketFirm = useSelector(selectBasketFirm);
  const basketManager = useSelector(selectBasketManager);
  const basketConfirm = useSelector(selectBasketConfirm);
  const basketPriceScheme = useSelector(selectBasketPriceScheme);
  const basketComent = useSelector(selectBasketComent);
  const basketDelivery = useSelector(selectBasketDelivery);

  const [selectShow, setSelectShow] = useState(false);
  const [vendor, setVendor] = useState(current_vendor);
  const selectRef = useRef(null);
  const [priceList, setPriceList] = useState([]);

  const handleClick = (id) => {
    apiaxios
      .get("/catalog/get_prices_for_basket_row_vendor_select?id=" + id)
      .then((res) => {
        setPriceList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const basketChange = (newVendor, newPrice, newDeliveryDate) => {
    let newBasket = basket.map((elem) => {
      if (elem.row_num === row_num) {
        return {
          ...elem,
          price_in: Number(newPrice),
          vendor: newVendor,
          plan_date: newDeliveryDate,
        };
      }
      return elem;
    });

    const amount = newBasket.reduce(
      (total, item) => total + item.price_im * item.quantity,
      0
    );
    const amountIn = newBasket.reduce(
      (total, item) => total + item.price_in * item.quantity,
      0
    );
    const count = newBasket.reduce((total, item) => total + item.quantity, 0);

    // Знаходження максимальної plan_date
    const changedPlanDate = newBasket.reduce((maxDate, item) => {
      if (item.plan_date) {
        const currentDate = new Date(item.plan_date);
        return currentDate > maxDate ? currentDate : maxDate;
      }
      return maxDate;
    }, new Date(0));

    // Форматування дати у формат YYYY-MM-DD
    const formattedPlanDate = changedPlanDate.toISOString().split("T")[0];

    dispatch(changeBasketItems(newBasket));
    dispatch(changeBasketCount(count));
    dispatch(changeBasketAmount(amount));
    dispatch(changeBasketAmountIn(amountIn));
    dispatch(changeBasketPlanDate(formattedPlanDate));
    saveBasket(
      userStatus,
      dispatch,
      basketOrderID,
      basketDate,
      newBasket,
      count,
      amount,
      amountIn,
      basketClient,
      basketWarehouse,
      basketFirm,
      basketManager,
      basketConfirm,
      basketPriceScheme,
      formattedPlanDate,
      basketComent,
      basketDelivery
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setSelectShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {!selectShow && (
        <div
          onClick={() => {
            setSelectShow(true);
            handleClick(id);
          }}
        >
          <div className={css["quantity-input-order"]}>{current_vendor}</div>
        </div>
      )}
      {selectShow && (
        <select
          id="select"
          name="select"
          value={vendor}
          ref={selectRef}
          onChange={(e) => {
            const selectedOption = priceList.find(
              (item) => item["vendor"] === e.target.value
            );
            setVendor(e.target.value);
            setSelectShow(false);
            basketChange(
              e.target.value,
              selectedOption["price"],
              selectedOption["delivery_date"]
            );
          }}
        >
          {priceList
            .filter((item) => item["available"])
            .map((item) => (
              <option key={item["vendor_code"]} value={item["vendor"]}>
                {item["vendor"] +
                  ": " +
                  item["price"] +
                  ", к-ть: " +
                  item["quantity"] +
                  ", на " +
                  item["delivery_date"]}
              </option>
            ))}
        </select>
      )}
    </>
  );
};

export default BasketRowVendorSelect;
